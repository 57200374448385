import React from "react";
import * as Bi from "react-icons/bi";
import * as Gr from "react-icons/gr";
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel, Typography } from "@material-tailwind/react";
import { motion } from "framer-motion";
import contactImg from "../assets/img/troy_tech_web_bg.jpg";
import webprocess from "../assets/img/troy_tech_v model_software_development.jpg";

const data = [
  {
    label: "Services",
    value: "services",
    icon: <Bi.BiCog size={20} />,
    desc: [
      {
        title: "Agile and Responsive",
        text: "Our software outsourcing services range from technology consulting and business analysis to QA, application maintenance and solution integration. Our integrated service lines have been shaped by nearly two decades of delivering value to our customers, and are built on a deep understanding of business needs and market trends, along with the technology expertise and software industry best practices.",
      },
      {
        title: "Mature and Flexible",
        text: "We are fully equipped to deliver first-class services that result in improved business efficiency, increased ROI cost-effectiveness, risk mitigation for our clients, not to speak of transparency and predictability that are guaranteed by default. We rely on multi-disciplinary software developers, time-proven processes, tools and techniques to bring the highest level of quality to our growing range of services. At the same time we are highly flexible to meet the needs of various customers – from startups and SMBs to Fortune 500 companies.",
      },
      {
        title: "We provide",
        text: "Technology Consulting, Software Prototyping, Custom Software Development, Software Product Development, Enterprise Application Integration, Application Security, Virtualization Management, Software QA and Testing, Maintenance & Support and Dedicated Development Centers",
      },
    ],
  },
  {
    label: "Solutions",
    value: "solutions",
    icon: <Bi.BiHive size={20} />,
    desc: [
      {
        title: "Value-Added Solutions & Domains Expertise",
        text: "Our solution offerings are rooted in a deep understanding of your business requirements and goals, along with market trends and insights",
      },
      {
        title: "Complex Solutions Made Easy",
        text: "From advanced Portals and eCommerce solutions to ERP & BI, we are dedicated to build easy-to-use solutions (no matter how complex they could be) either from scratch or as a combination of out-of-the-box modules for you to reach your business goals the shortest way.",
      },
      {
        title: "Commerce",
        text: "The entire bunch of B2B/B2C omnichannel selling and fulfillment needs to benefit consumers, employees and partners",
      },
      {
        title: "B2B and B2C Web Portals",
        text: "Advanced solutions for customer communication and service, supply chain management, sales automation and other business",
      },
      {
        title: "Enterprise Portals & Intranets",
        text: "Accelerated business processes through more efficient utilization of corporate information resources",
      },
      {
        title: "Content Management and Distribution",
        text: "Heterogeneous content from diverse sources consolidated into a single centrally managed repository with accurate data",
      },
      {
        title: "Enterprise Content/Document Management",
        text: "Facilitated document-related operations – document capturing, routing, life cycle management, search, etc",
      },
      {
        title: "Business Intelligence",
        text: "Enterprise-wide reporting, online analytical processing, data mining, benchmarking and business performance management",
      },
      {
        title: "Enterprise Resource Planning",
        text: "Rich functionality – from finance and human resources management to procurement and order processing to boost",
      },
      {
        title: "Customer Relationship Management",
        text: "Accelerated business processes − from account management & marketing campaigns to sales automation & customer service",
      },
    ],
  },
  {
    label: "Technology",
    value: "technology",
    icon: <Gr.GrTechnology size={20} />,
    desc: [
      {
        title: "Technology Excellence and Maturity",
        text: "We nurture our technology competencies to enhance your business and help you achieve your goals",
      },
      {
        title: "Reliable Partner, Not Just a Vendor",
        text: "Since 2014, we’ve been maintaining a comprehensive technology stack for we know how to efficiently combine legacy and innovation to address your business challenges with the right technology. Our software developers know how to adopt and ramp up emerging programming technologies quickly to execute the most ambitious projects and deliver the right results in terms of efficiency, performance, interoperability, and user experience.",
      },
      {
        title: "Collaboration Matters",
        text: "We will guide you through all the technologies and platforms available on the market to find your ideal solution. At the same time we always encourage you to share with us all your technology requirements, wishes and concerns. While our experience and expertise are fully at your service, we do believe that only close collaboration with our customers all the way a project goes can bring tangible results.",
      },
    ],
  },
];

const Software = () => {
  return (
    <div>
      <div
        className="w-full h-[250px] relative before:content-[''] before:w-full before:bg-[#0b1643] before:opacity-65 before:absolute before:top-0 before:left-0 before:h-full before:z-0 "
        style={{ backgroundImage: `url(${contactImg})`, backgroundPosition: "center", backgroundSize: "cover" }}>
        <div className="w-full h-full flex flex-col items-center justify-center gap-4 relative">
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.8 }}
            className="xl:w-[70%] flex justify-center items-center">
            <Typography variant="h2" className="capitalize text-3xl md:text-4xl">
              software development
            </Typography>
          </motion.div>
          <div className="max-w-[650px] text-center p-4">
            <motion.p
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ type: "spring", stiffness: 100, damping: 10, delay: 1 }}
              className="text-sm md:line-clamp-none line-clamp-3">
              Softwar are a major fragment of this life's changing pack and that is why it is of great significance in recent times.
            </motion.p>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-center justify-center">
        <div className="xl:w-[70%] flex flex-wrap gap-8 py-4 md:py-16">
          <div className="flex-grow w-96">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.4 }}
              className="w-full flex justify-center items-center">
              <img src={webprocess} alt="what we do" />
            </motion.div>
          </div>
          <div className="flex-grow w-96 flex items-center px-4">
            <motion.div initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1, y: 0 }} transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.6 }} className="">
              <Typography variant="h3" className="mb-8 font-semibold">
                Software Construction
              </Typography>
              <Typography variant="paragraph" className="leading-7 mb-4">
                For your commercial or customized software, Troy Technology Ghana is the right company you should speak to. We make your work faster, easier and more convenient.
                Let's turn your dreams and imaginations into reality.
              </Typography>
              <Typography variant="paragraph" className="leading-7 mb-4">
                Our Custom Software Development solutions tackle your business needs by automating your business processes into real value software applications.You do not need to
                re-structure your business processes in order to use some off-the-shelf software; we take our time to understand your business processes carefully and then use
                technologies that will suite the development.
              </Typography>
              <Typography variant="paragraph" className="leading-7 mb-4">
                We are experts in rapid custom development of web-based, distributed and standalone applications designed to meet your organization's specific requirements and
                business needs. We follow proven practices that allow for successful project completion, and deliver solutions that exceed customer expectations as well as
                attractive pricing.
              </Typography>
              <Typography color="green">Contact us now to help put your product or business on the global platform today.</Typography>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Tabs */}
      <div className=" w-full flex flex-col items-center justify-center my-8">
        <div className="xl:w-[70%] max-w-full px-2">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.6 }}
            className="w-full ">
            <Tabs value="html">
              <TabsHeader>
                {data.map(({ label, value, icon }) => (
                  <Tab key={value} value={value}>
                    <div className="flex justify-center items-center gap-2 md:gap-6">
                      <div className="w-5 h-5">{icon}</div>
                      <div className="">{label}</div>
                    </div>
                  </Tab>
                ))}
              </TabsHeader>
              <TabsBody
                animate={{
                  initial: { y: 250 },
                  mount: { y: 0 },
                  unmount: { y: 250 },
                }}>
                {data?.map(({ value, desc }) => (
                  <TabPanel key={value} value={value} className="text-white">
                    {desc?.map((item, index) => (
                      <div key={index} className="flex flex-col gap-2 mb-8">
                        <Typography variant="h4">{item?.title}</Typography>
                        <Typography variant="paragraph">{item?.text}</Typography>
                      </div>
                    ))}
                  </TabPanel>
                ))}
              </TabsBody>
            </Tabs>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Software;
