import React, { useState } from "react";
import * as Bi from "react-icons/bi";
import logo from "../assets/img/troy-tech-logo.png";
import { Link, NavLink } from "react-router-dom";
import Modal from "./Modal";
import { motion } from "framer-motion";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen((prev) => (prev = !isOpen));
  };

  // console.log(isOpen);
  return (
    <>
      <div className="flex justify-center sticky w-full -top-1 z-40 bg-[#0b1643]">
        <div className="xl:w-[70%] w-full">
          <div className="md:px-10 py-4 px-7 md:flex justify-between items-center">
            {/* logo */}
            <motion.div initial={{ opacity: 0, y: -100 }} animate={{ opacity: 1, y: 0 }} transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.2 }} className="w-64">
              <div>
                <Link to="/" className="w-[200px] md:w-[300px] flex justify-center items-center">
                  <img src={logo} alt="" className="w-20" />
                  <h2 className="invisible md:visible md:font-semibold md:text-xl ">Troy Technology Ghana Limited</h2>
                </Link>
              </div>
            </motion.div>

            {/* mobile menu */}
            <motion.div
              initial={{ opacity: 0, y: -100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.2 }}
              className="xl:hidden flex w-30 cursor-pointer absolute xl:right-28 right-8 top-10"
              onClick={handleOpen}>
              {isOpen ? <Bi.BiX className="text-2xl" /> : <Bi.BiMenuAltRight className="text-2xl" />}
            </motion.div>

            {/* menu items */}
            <motion.div
              initial={{ opacity: 0, y: -100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.2 }}
              className={`flex flex-col xl:flex-row gap-10 w-full justify-center items-start xl:items-center absolute xl:static bg-[#0b1643] xl:z-30 z-30 left-0 xl:w-auto xl:pl-0 pl-9 py-4 transition-all duration-500 ease-in ${
                isOpen ? "top-24" : "top-[-490px]"
              }`}>
              <NavLink to="/" onClick={handleOpen}>
                Home
              </NavLink>
              <NavLink to="/website" onClick={handleOpen}>
                Website
              </NavLink>
              <NavLink to="/networking" onClick={handleOpen}>
                Networking
              </NavLink>
              <NavLink to="/software" onClick={handleOpen}>
                Software
              </NavLink>
              <NavLink to="/contact" onClick={handleOpen}>
                Contact
              </NavLink>
              <div className="z-20">
                <Modal />
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
