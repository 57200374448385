import React, { useEffect, useState } from "react";
import Button from "./Button";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInputWithCountrySelect from "react-phone-number-input/react-hook-form";
import { Input, Textarea } from "@material-tailwind/react";
import emailjs from "@emailjs/browser";

const Modal = () => {
  const [showModal, setShowModal] = useState(false);

  const schema = yup.object().shape({
    fullname: yup.string().required("Your Full Name is Required"),
    email: yup.string().email().required("Your Email is Required"),
    phone: yup.string().required("Your Phone Number is Required"),
    message: yup.string().required("Your Message is Required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    emailjs.send("service_4hxwvt6", "template_xkltdqp", data, "PFk-lXF0ExfG9fQHM").then(
      () => {
        console.log("Success");
        // setMsgres("Thanks, you will hear from us soon!");
      },
      (error) => {
        console.log("Failed...", error.text);
      }
    );

    // console.log(data);
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      setShowModal(false);
    }
  }, [isSubmitSuccessful]);

  return (
    <>
      {/* <button
        className="py-2 px-3 rounded-md capitalize bg-gradient-to-tr from-cyan-500 to-blue-500 active:bg-blue-500 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 z-30"
        type="button"
        onClick={() => setShowModal(true)}>
        Let's Talk
      </button> */}
      <Button children="Let's Talk" onClick={() => setShowModal(true)} primary rounded />
      {showModal ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none transition-all duration-500 ease-in-out">
            <div className="relative w-96 my-6 mx-auto max-w-7xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-500 rounded-t ">
                  <h3 className="text-3xl text-blue-800 font-semibold">General Info</h3>
                  <button className="bg-transparent border-0 text-black float-right" onClick={() => setShowModal(false)}>
                    <span className="text-black opacity-7 h-6 w-6 text-xl block py-0 rounded-full">x</span>
                  </button>
                </div>
                <div className="relative p-6 pb-0 flex-auto">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col gap-4">
                      <Input type="text" label="Your Name" {...register("fullname")} size="lg" color="green" className="" />
                      {errors ? <span className="text-red-400 text-xs md:text-sm ">{errors.fullname?.message}</span> : ""}

                      <Input type="email" label="Your Email" {...register("email")} size="lg" color="green" className="" />
                      {errors ? <span className="text-red-400 text-xs md:text-sm ">{errors.email?.message}</span> : ""}

                      <PhoneInputWithCountrySelect
                        name="phone"
                        control={control}
                        defaultCountry="GH"
                        rules={{ required: true }}
                        limitMaxLength
                        placeholder="Your Phone Number"
                        className="text-black"
                      />
                      {errors ? <span className="text-red-400 text-xs md:text-sm ">{errors.phone?.message}</span> : ""}

                      <Textarea type="" label="Your Message" {...register("message")} rows={6} color="green" className="" />
                      {errors ? <span className="text-red-400 text-xs md:text-sm ">{errors.message?.message}</span> : ""}

                      <div className="flex items-center justify-end p-6 border-t border-solid border-gray-400 gap-4">
                        <Button children="Close" onClick={() => setShowModal(false)} secondary rounded />
                        <Button children="Submit" primary rounded />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-black opacity-40 w-full h-[100%] fixed inset-0 z-40 cursor-pointer transition-all duration-500 ease-in-out" />
        </>
      ) : null}
    </>
  );
};

export default Modal;
