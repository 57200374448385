import React from "react";
import { motion } from "framer-motion";
import { Typography } from "@material-tailwind/react";
import networkImg from "../assets/img/troy_tech_creative_bg.jpg";
import onDemand from "../assets/img/troytech_on_demand.png";
import cyber from "../assets/img/troytech_cyber.png";
import cloud from "../assets/img/troytech_cloud.png";
import strategy from "../assets/img/troytech_strategy.png";
import compliant from "../assets/img/troytech_compliant.png";
import business from "../assets/img/troytech_business.png";
import GlassCard from "../components/GlassCard";
import maintenance from "../assets/img/troy-networking-maintenance.png";
import cctv from "../assets/img/troy_cctv-icon.png";
import service from "../assets/img/troy-gear-technology.png";
import upgrade from "../assets/img/troy_gear_upload.png";

const Networking = () => {
  return (
    <div>
      <div
        className="w-full h-[250px] relative before:content-[''] before:w-full before:bg-[#0b1643] before:opacity-65 before:absolute before:top-0 before:left-0 before:h-full before:z-0 "
        style={{ backgroundImage: `url(${networkImg})`, backgroundPosition: "center", backgroundSize: "cover" }}>
        <div className="w-full h-full flex flex-col items-center justify-center gap-4 relative">
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.8 }}
            className="xl:w-[70%] flex justify-center items-center">
            <Typography variant="h2" className="capitalize text-3xl md:text-4xl">
              Network System
            </Typography>
          </motion.div>
          <div className="max-w-[650px] text-center p-4">
            <motion.p
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ type: "spring", stiffness: 100, damping: 10, delay: 1 }}
              className="text-sm md:line-clamp-none line-clamp-3">
              LANs, WANs, Infrastructure, Wireless Systems professionally with our specialised team.
            </motion.p>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-center justify-center">
        <div className="xl:w-[70%] flex flex-wrap gap-8 py-4 md:py-16">
          <div className="flex-grow w-96 flex items-center px-4">
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.6 }}
              className="text-center">
              <Typography variant="paragraph" className="leading-7 mb-4">
                Troy provides you an Enterprise computing involving the use of Computers in networks, such as LANs and WANs, or a series of interconnected network peripherals that
                encompass a variety of different operating Systems, protocols, and network architectures, to set up a full network for your organization for easy file transfer and
                safety. The emerging information and knowledge age and the new technological evolution are heralding a new economic and social order characterized by the deployment
                and exploitation of IT within all spheres of human endeavor.
              </Typography>
              <Typography variant="paragraph" className="leading-7 mb-4">
                The socio-economic environment is rapidly changing on global scale. Information Technologies (IT) is now acknowledged globally as agents of change especially within
                the public sector to enhance the service delivery to the populace.
              </Typography>
            </motion.div>
          </div>
        </div>
      </div>

      <div className="relative flex flex-col items-center justify-center my-8">
        <div className="xl:w-[70%]">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.2 }}
            className="flex flex-col justify-center items-center my-8">
            <Typography variant="h3" className="xl:text-5xl text-3xl font-extrabold capitalize">
              what You Get
            </Typography>
            <Typography variant="paragraph" className=" font-extralight text-blue-500">
              Everything we offer for a predictable monthly fee.
            </Typography>
          </motion.div>
          <div className="flex flex-wrap justify-center items-center gap-8">
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.4 }}
              className="md:w-80 ">
              <GlassCard
                icon={<img src={onDemand} alt="on-demand-service" />}
                title="On Demand Service"
                content="A support team that solves your issues 40% faster than the rest of the industry."
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.4 }}
              className="md:w-80 ">
              <GlassCard
                icon={<img src={strategy} alt="technical-strategy" />}
                title="technical strategy"
                content="A dedicated strategy team obsessed with finding ways to accelerate your growth...and never slow you down."
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.4 }}
              className="md:w-80 ">
              <GlassCard
                icon={<img src={compliant} alt="compliance" />}
                title="compliance"
                content="From HIPAA to CMMC and everything in between, our in-house compliance experts ensure you're ready."
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.4 }}
              className="md:w-80 ">
              <GlassCard icon={<img src={cloud} alt="cloud-service" />} title="cloud service" content="10 years of helping small businesses leverage cloud technologies to grow." />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.4 }}
              className="md:w-80 ">
              <GlassCard
                icon={<img src={cyber} alt="cyber-security" />}
                title="cyber security"
                content='The newest cybersecurity "tools" are not enough. We make your business a hard target so the bad guys pass you by.'
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.4 }}
              className="md:w-80 ">
              <GlassCard
                icon={<img src={business} alt="business-automation" />}
                title="business automation"
                content='Operational consulting that creates efficiencies and uses AI automation to give your business an "unfair" advantage.'
              />
            </motion.div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-center justify-center mt-8">
        <div className="xl:w-[70%] flex flex-wrap gap-8 py-4 md:py-16">
          <div className="flex-grow w-96 px-4 order-last md:order-first flex items-center">
            <motion.div initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1, y: 0 }} transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.2 }} className="">
              <Typography variant="h3" className="mb-8 font-semibold">
                System Maintenance
              </Typography>
              <Typography variant="paragraph" className="leading-7 mb-4">
                In order to achieve our objective of protecting investments in IT in your organization through well planned and execution of corrective and preventive maintenance,
                TROY Technology intend to start work within two (2) weeks after signing of contract.
              </Typography>
              <Typography variant="paragraph" className="leading-7 mb-4">
                We shall have a first meeting with your organization to get to know how staff use their computers and peripherals, and provide technical advice and guidelines on
                equipment if needed. After this, we shall deploy our highly SKILLED staff to come over to your premises and carry out the cleaning (servicing) of computers and
                peripherals. This cleaning of IT equipment will be done once every month.
              </Typography>
              <Typography variant="paragraph" className="leading-7 mb-4">
                To ensure that clients get the support they need for the IT equipment to work all the time, we shall provide support within 24 hours of contacting us- by phone,
                email or any other means. During the period of servicing the equipment, however, those that have technical problems will be fixed immediately.
              </Typography>
              <Typography variant="paragraph" className="leading-7 mb-4">
                When there is the need to replace a part of any IT equipment, your office will be informed and on your approval, this will be purchased. One week after every
                general servicing, TROY technologies shall provide you with status report on all the equipment in your organization. The report shall also contain recommendations
                on what needs to be done.
              </Typography>
            </motion.div>
          </div>
          <div className="flex-grow w-96">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.2 }}
              className="w-full flex justify-center items-center">
              <img src={maintenance} alt="what we do" />
            </motion.div>
          </div>
        </div>
      </div>

      <div className="relative flex flex-col items-center justify-center my-8 mb-16">
        <div className="xl:w-[70%]">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.2 }}
            className="flex flex-col justify-center items-center my-8">
            <Typography variant="h3" className="xl:text-3xl text-2xl font-extrabold capitalize">
              Our Other Services
            </Typography>
          </motion.div>

          <div className="flex justify-between flex-wrap gap-12 pt-8">
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.6 }}
              className="flex-grow basis-52 w-96 text-center flex flex-col items-center gap-4">
              <img src={upgrade} alt="" className="w-[100px] h-[100px]" />
              <Typography variant="paragraph">Setting up new equipments and upgrading existing systems</Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.6 }}
              className="flex-grow basis-52 w-96 text-center flex flex-col items-center gap-4">
              <img src={service} alt="" className="w-[130px] h-[100px]" />
              <Typography variant="paragraph">Servicing and fixing equipments</Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.6 }}
              className="flex-grow basis-52 w-96 text-center flex flex-col items-center gap-4">
              <img src={cctv} alt="" className="w-[100px] h-[100px]" />
              <Typography variant="paragraph">CCTV Sales and installations</Typography>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Networking;
