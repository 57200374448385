import { Typography } from "@material-tailwind/react";
import React from "react";

const GlassCard = ({ icon, title, content }) => {
  return (
    <div
      className="flex-grow basis-52 w-full p-2 py-8 rounded-xl shadow-md "
      style={{ background: "rgba(255,255,255,0.08)", backdropFilter: "blur(3px)", border: "1px solid rgba(255,255,255,0.25)" }}>
      <div className="flex flex-col items-center">
        <div className="hover:scale-110 duration-300">{icon}</div>
        <div className="px-4 flex flex-col items-center mt-4 gap-2">
          <Typography variant="h3" className="text-xl uppercase">
            {title}
          </Typography>
          <p className="line-clamp-3 text-center text-sm">{content}</p>
        </div>
      </div>
    </div>
  );
};

export default GlassCard;
