import { Route, Routes } from "react-router-dom";
import Home from "./screens/Home";
import Website from "./screens/Website";
import Networking from "./screens/Networking";
import Contact from "./screens/Contact";
import NotFound from "./screens/NotFound";
import Header from "./components/Header";
import Software from "./screens/Software";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/website" element={<Website />} />
        <Route path="/networking" element={<Networking />} />
        <Route path="/software" element={<Software />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
