import React from "react";
import * as Bi from "react-icons/bi";
import { clients } from "../data/clients";
import bgBanner from "../assets/img/troy_tech_home-banner.png";
import bgService from "../assets/img/troy_tech_service_bg.jpg";
import whatwedo from "../assets/img/troy_tech_1.png";
import creativebg from "../assets/img/troy_tech_creative_bg.jpg";
// import { Link } from "react-router-dom";
import { Button, Typography } from "@material-tailwind/react";
import MyMarquee from "../components/MyMarquee";
import { motion } from "framer-motion";
import GlassCard from "../components/GlassCard";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  return (
    <div className="relative flex flex-col items-center justify-center">
      {/* <div className="absolute left-0 top-64 z-0 w-11 h-11 bg-white rounded-full blur-xl opacity-60" /> */}

      <div className="xl:w-[70%]">
        {/* Banner */}
        <div className="w-full flex justify-center">
          <div className="flex flex-wrap">
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.8 }}
              className="w-full xl:w-1/3 p-8 xl:mt-12 mt-0 ">
              {/* <h5 className=" text-blue-500 capitalize mb-4">welcome to the best</h5> */}
              <Typography variant="h3" color="blue" className="capitalize font-extralight mb-2">
                the best
              </Typography>
              <Typography variant="h1" className=" text-6xl capitalize font-black mb-6">
                IT solution center
              </Typography>
              {/* <p className="mb-4 leading-7">
                The better the connection, the better the experience. At Troy Technology, our mission is to develop the best products, deliver award winning customer service, and
                simply be the company you want to do business with.
              </p> */}
              <Typography variant="paragraph" className="leading-8">
                What's worked for us is not quitting but being passionate about what we do and not giving up on our preparation; We are passionate and prepare more than anyone else
                to add real value to people's lives.
              </Typography>
              <div className="mt-8 flex gap-3">
                <Button className="bg-gradient-to-tr from-cyan-500 to-blue-500 py-5" onClick={() => navigate("/contact")}>
                  contact now
                </Button>
                <Button variant="outlined" onClick={() => navigate("/website")} className="hover:bg-gradient-to-tr from-cyan-500 to-blue-500 border-blue-500 text-white py-5">
                  browse More
                </Button>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ type: "spring", stiffness: 100, damping: 10, delay: 1 }}
              className="relative w-full xl:w-2/3 p-8">
              {/* <div className="absolute left-[30%] top-[20%] -z-10 w-[50%] h-[50%] bg-gradient-to-t from-blue-800 to-blue-200 rounded-full blur-3xl " /> */}
              <img src={bgBanner} alt="troy-tech-home-banner" className="w-full object-contain" />
            </motion.div>
          </div>
        </div>
      </div>

      {/* home service */}
      <div
        className="w-full  flex justify-center items-center pb-12 relative before:content-[''] before:w-full before:bg-[#0b1643] before:opacity-65 before:absolute before:top-0 before:left-0 before:h-full "
        style={{ backgroundImage: `url(${bgService})`, backgroundPosition: "center center", backgroundSize: "cover" }}>
        <div className="absolute top-0 left-0 z-10 w-full h-[20vh] bg-gradient-to-b from-[#0b1643] to-transparent" />

        <div className=" xl:w-[70%] px-4 z-10">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.2 }}
            className="flex flex-col justify-center items-center my-16 pt-4">
            <Typography variant="h3" className=" font-extralight text-blue-500">
              our service
            </Typography>
            <Typography variant="h2" className="xl:text-6xl text-3xl font-extrabold capitalize">
              what we do
            </Typography>
          </motion.div>

          {/* Glass Card Section */}
          <div className="flex flex-wrap justify-center gap-4">
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.4 }}
              className="md:w-80 ">
              <GlassCard
                icon={<Bi.BiGlobe className="text-9xl " />}
                title="website"
                content="Owing to technological advancement and the sky rocketing advantages of the internet, Troy technology is providing small and large scale companies with intuitive
                    and top notch websites for a cool price,"
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.6 }}
              className="md:w-80">
              <GlassCard
                icon={<Bi.BiNetworkChart className="text-9xl " />}
                title="networking"
                content="Troy provides you an Enterprise computing involving the use of Computers in networks, such as LANs and WANs, or a series of interconnected network peripherals
                    that encompass a variety of different operating Systems, protocols, and network architectures,"
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.8 }}
              className="md:w-80">
              <GlassCard
                icon={<Bi.BiCodeAlt className="text-9xl " />}
                title="software"
                content="For your commercial or customized software, Troy is the right company you should speak to. We make your work faster, easier and more convenient. Let's turn your
                    dreams and imaginations into reality."
              />
            </motion.div>
          </div>
        </div>
      </div>

      {/*  */}
      <div className="w-full flex flex-col items-center justify-center">
        <div className="xl:w-[70%] flex flex-wrap gap-4 py-16">
          <div className="flex-grow w-96">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.2 }}
              className="w-full flex justify-center items-center">
              <img src={whatwedo} alt="what we do" />
            </motion.div>
          </div>
          <div className="flex-grow w-96 px-4">
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.6 }}
              className="py-24">
              <Typography variant="h3" className="mb-8 font-semibold">
                We complete very projects with extra care as customer need
              </Typography>
              <Typography variant="paragraph" className="leading-7 mb-4">
                We are dedicated to making your work much more easy and ensuring consistent efficiency, bringing your ideas to life through quality, creative designs and software,
                networking your computers and providing top-notch solution for all your technology related issues. We develop your softwares and websites based solely on what our
                customers want. Our well trainned personnel are patient enough to walk our cherished clients through all our procedures and are open to all questions troubling the
                clients mind.
              </Typography>
              <Button className="bg-gradient-to-tr from-cyan-500 to-blue-500" onClick={() => navigate("/website")}>
                Read More
              </Button>
            </motion.div>
          </div>
        </div>
      </div>

      {/*  */}
      <div
        className="w-full  relative before:content-[''] before:w-full before:bg-[#0b1643] before:opacity-65 before:absolute before:top-0 before:left-0 before:h-full before:z-0 "
        style={{ backgroundImage: `url(${creativebg})`, backgroundPosition: "bottom", backgroundSize: "cover" }}>
        <div className="w-full flex flex-col items-center justify-center relative">
          <div className="xl:w-[70%] flex flex-wrap gap-8 py-16 px-4">
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.2 }}
              className="flex-grow w-96 text-center">
              <Typography variant="h3" className=" capitalize text-blue-500 font-bold mb-4">
                creative design
              </Typography>
              <Typography variant="paragraph">Our design style is strategically inventive to attain your, marketing goals and visually depict your brand.</Typography>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.4 }}
              className="flex-grow w-96 text-center">
              <Typography variant="h3" className=" capitalize text-blue-500 font-bold mb-4">
                experience & interaction
              </Typography>
              <Typography variant="paragraph">We develop exciting interactive sites to extend user, engagement while process a transparent user journey</Typography>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.6 }}
              className="flex-grow w-96 text-center">
              <Typography variant="h3" className=" capitalize text-blue-500 font-bold mb-4">
                quality assured
              </Typography>
              <Typography variant="paragraph">
                All our development app are tested across multiple devices so that, the mobile & desktop users have the same great experience.
              </Typography>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Our Client */}
      <MyMarquee data={clients} caption="our clients" />
    </div>
  );
};

export default Home;
