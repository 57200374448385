import web1 from "../assets/img/Django-300x137.png";
import web2 from "../assets/img/Wordpress1-300x300.png";
import web3 from "../assets/img/css-3-300x300.png";
import web4 from "../assets/img/html5-300x300.png";
import web5 from "../assets/img/js-logo.png";
import web6 from "../assets/img/laravel-php-300x300.png";
import web7 from "../assets/img/nodejs-image.webp";
import web8 from "../assets/img/php1-300x158.png";
import web9 from "../assets/img/python1-300x250.png";
import web10 from "../assets/img/react1-300x285.png";

export const webbtools = [
  {
    img: web1,
  },
  {
    img: web2,
  },
  {
    img: web3,
  },
  {
    img: web4,
  },
  {
    img: web5,
  },
  {
    img: web6,
  },
  {
    img: web7,
  },
  {
    img: web8,
  },
  {
    img: web9,
  },
  {
    img: web10,
  },
];
