import { Button, Input, Textarea, Typography } from "@material-tailwind/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import contactImg from "../assets/img/troy_tech_contact_bg.png";
import { motion } from "framer-motion";
import PhoneInputWithCountrySelect from "react-phone-number-input/react-hook-form";
import emailjs from "@emailjs/browser";

// const initailState = {
//   fullname: "",
//   email: "",
//   phone: "",
//   message: "",
// };

const Contact = () => {
  // const [formData, setFormData] = useState(initailState);
  // const { fullname, email, phone, message } = formData;

  const schema = yup.object().shape({
    fullname: yup.string().required("Your Full Name is Required"),
    email: yup.string().email().required("Your Email is Required"),
    phone: yup.string().required("Your Phone Number is Required"),
    message: yup.string().required("Your Message is Required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    emailjs.send("service_4hxwvt6", "template_xkltdqp", data, "PFk-lXF0ExfG9fQHM").then(
      () => {
        console.log("Success");
        // setMsgres("Thanks, you will hear from us soon!");
      },
      (error) => {
        console.log("Failed...", error.text);
      }
    );

    // console.log(data);
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <div>
      <div
        className="w-full h-[300px] relative before:content-[''] before:w-full before:bg-[#0b1643] before:opacity-65 before:absolute before:top-0 before:left-0 before:h-full before:z-0 "
        style={{ backgroundImage: `url(${contactImg})`, backgroundPosition: "center", backgroundSize: "cover" }}>
        <div className="w-full h-full flex flex-col items-center justify-center relative">
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.6 }}
            className="xl:w-[70%] flex justify-center items-center">
            <Typography variant="h2" className="text-4xl capitalize">
              contact us
            </Typography>
          </motion.div>
          <div className="max-w-[650px] text-center p-4">
            <motion.p
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ type: "spring", stiffness: 100, damping: 10, delay: 1 }}
              className="text-sm md:line-clamp-none line-clamp-3">
              …a company that stands for what it believes in, which is making the impossible possible within the scope of technology.
            </motion.p>
          </div>
        </div>
      </div>

      <div className="relative flex flex-col items-center justify-center my-8">
        <div className="xl:w-[70%]">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.2 }}
            className="flex flex-col justify-center items-center my-8">
            <Typography variant="h3" className="xl:text-3xl text-2xl font-extrabold capitalize mb-5">
              Why Choose Us
            </Typography>
            <Typography variant="paragraph" className="leading-7 mb-4 text-center">
              We are dedicated to making your work much more easy and ensuring consistent efficiency, bringing your ideas to life through quality, creative designs and software,
              networking your computers and providing top-notch solution for all your technology related issues. We develop your softwares and websites based solely on what our
              customers want.Our well trainned personnel are patient enough to walk our cherished clients through all our procedures and are open to all questions troubling the
              clients mind.
            </Typography>
            <Typography variant="paragraph" className="leading-7 mb-4 text-center">
              Consisting of a team of young, talented and action driven professionals, we guarantee you an amazing experience working with us. Our highly skilled personnel are
              specilised in mordern technologies that is the driving force behind all these great inventions in recent times. Efficiency is a hall mark and our level of excellent
              delivery cannot be matched with that of any other entity.
            </Typography>
          </motion.div>
        </div>
      </div>

      <div className="w-full flex flex-col items-center justify-center">
        <div className="xl:w-[70%] flex flex-wrap gap-4 py-16">
          <div className="flex-grow w-96">
            <motion.div initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1, y: 0 }} transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.2 }} className="">
              <Typography variant="h3" className="xl:text-3xl text-2xl font-extrabold capitalize mb-5">
                Our Mission
              </Typography>
              <Typography variant="paragraph" className="leading-7 mb-4 text-justify">
                At Troy Technology Ghana, we're dedicated to bridging the gap between people and technology, crafting experiences that are seamless, secure, and impactful, and
                enriching the lives of those we serve.
              </Typography>
            </motion.div>
          </div>
          <div className="flex-grow w-96 px-4">
            <motion.div initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1, y: 0 }} transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.2 }} className="">
              <Typography variant="h3" className="xl:text-3xl text-2xl font-extrabold capitalize mb-5">
                Our Vision
              </Typography>
              <Typography variant="paragraph" className="leading-7 mb-4 text-justify">
                Our vision at Troy Technology Ghana limited is to build a better tomorrow by leveraging technology to solve real-world problems. We're committed to creating
                solutions that promote sustainability, equality, and social responsibility, inspiring a brighter future for all.
              </Typography>
            </motion.div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-center justify-center relative">
        <div className="xl:w-[70%] flex flex-wrap gap-4 py-16">
          <div className="flex-grow w-96">
            <div className="px-4">
              <motion.div initial={{ opacity: 0, y: 100 }} animate={{ opacity: 1, y: 0 }} transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.6 }} className="mb-8">
                <Typography variant="h5">Please fill the form below to leave a message</Typography>
              </motion.div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <motion.div
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.6 }}
                  className="flex flex-col gap-4">
                  <Input type="text" label="Your Full Name" {...register("fullname")} size="lg" color="green" className="text-white" />
                  {errors ? <span className="text-red-400 text-xs md:text-sm ">{errors.fullname?.message}</span> : ""}

                  <Input type="email" label="Your Email" {...register("email")} size="lg" color="green" className="text-white" />
                  {errors ? <span className="text-red-400 text-xs md:text-sm ">{errors.email?.message}</span> : ""}

                  <PhoneInputWithCountrySelect name="phone" control={control} defaultCountry="GH" rules={{ required: true }} limitMaxLength placeholder="Your Phone Number" />
                  {errors ? <span className="text-red-400 text-xs md:text-sm ">{errors.phone?.message}</span> : ""}

                  <Textarea type="" label="Your Message" {...register("message")} rows={10} color="green" className="text-white" />
                  {errors ? <span className="text-red-400 text-xs md:text-sm ">{errors.message?.message}</span> : ""}

                  <Button type="submit" className="bg-gradient-to-tr from-cyan-500 to-blue-500 cursor-pointer">
                    Send
                  </Button>
                </motion.div>
                {/* <div className="">{isSubmitSuccessful && "Message received Successfully, Thanks"}</div> */}
              </form>
            </div>
          </div>
          <div className="flex-grow w-96">
            <motion.div initial={{ opacity: 0, y: 100 }} animate={{ opacity: 1, y: 0 }} transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.6 }} className="mb-8">
              <Typography variant="h5">Locate us here</Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.6 }}
              style={{ width: "100%" }}>
              <iframe
                title="Troy"
                width="100%"
                height="500"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=500&amp;hl=en&amp;q=5.641478,%20-0.066534+(Troy%20Technology%20Ghana)&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                <a href="https://www.gps.ie/sport-gps/">hiking gps</a>
              </iframe>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
