import React from "react";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";

const My_marquee = ({ data, caption }) => {
  return (
    <div className="w-full flex flex-col items-center justify-center relative mb-9">
      <div className="xl:w-[70%] flex flex-wrap gap-8 py-4 md:py-16">
        <div className="flex-grow w-96 text-center">
          <div className="mb-8">
            <h4 className="font-semibold text-2xl capitalize">{caption}</h4>
          </div>
          <Marquee pauseOnHover={true} autoFill speed="30" gradient gradientColor="#0b1643" gradientWidth={50}>
            {data.map((web, index) => (
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.2 }}
                className="w-24 mx-8 overflow-hidden py4"
                key={index}>
                <img src={web.img} alt="clients" className="w-full object-cover " />
              </motion.div>
            ))}
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default My_marquee;
