import React from "react";
import className from "classnames";
// import PropTypes from "prop-types";

// const Button = ({ children, onClick }) => {
//   return (
//     <button
//       className="py-2 px-3 rounded-md capitalize bg-gradient-to-tr from-cyan-500 to-blue-500 active:bg-blue-500 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 z-30"
//       type="button"
//       onClick={onClick}>
//       {children}
//     </button>
//   );
// };

// export default Button;

export default function Button({ children, primary, secondary, success, warning, danger, outline, rounded, onClick }) {
  //1st arg:: for all variations.
  const classes = className("px-3 py-1.5 border", {
    "border-blue-500 bg-gradient-to-tr from-cyan-500 to-blue-500 text-white": primary,
    "border-cyan-500  text-blue-500": secondary,
    "border-green-500 bg-green-500 text-white": success,
    "border-yellow-400 bg-yellow-400 text-white": warning,
    "border-red-500 bg-red-500 text-white": danger,
    "rounded-md": rounded,
    "bg-white": outline,
    "text-blue-500": outline && primary,
    "text-gray-900": outline && secondary,
    "text-green-500": outline && success,
    "text-yellow-500": outline && warning,
    "text-red-500": outline && danger,
  });

  return (
    <button className={classes} onClick={onClick}>
      {children}
    </button>
  );
}

Button.propTypes = {
  checkVariationValue: ({ primary, secondary, success, danger, warning }) => {
    const count = Number(!!primary) + Number(!!secondary) + Number(!!success) + Number(!!danger) + Number(!!warning);
    if (count > 1) {
      return new Error("Only one of p, s, w , s ,d can be true");
    }
  },
};
