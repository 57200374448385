import client1 from "../assets/clients/anamon-logo.jpeg";
import client2 from "../assets/clients/blucarotte.png";
import client3 from "../assets/clients/arch-mo-ltd.jpeg";
import client4 from "../assets/clients/ctn-logo2.png";
import client5 from "../assets/clients/goshenEm.png";
import client6 from "../assets/clients/jobmartgh.jpg";
import client7 from "../assets/clients/wagcol-logo1.png";
import client8 from "../assets/clients/levi&trey.jpeg";
import client9 from "../assets/clients/boemengroup-logo.png";

export const clients = [
  {
    img: client1,
    link: "https://anamon.org/",
  },
  {
    img: client2,
    link: "https://blucarotte.com/",
  },
  {
    img: client3,
    link: "https://arch-mo.com/",
  },
  {
    img: client4,
    link: "https://ctncharity.org/",
  },
  {
    img: client5,
    link: "https://goshenemgh.com/",
  },
  {
    img: client6,
    link: "https://jobmartgh.com/",
  },
  {
    img: client7,
    link: "https://wagcolgh.com/",
  },
  {
    img: client8,
    link: "https://leviandtrey.com/",
  },
  {
    img: client9,
    link: "https://www.boemengroup.com/br/",
  },
];
