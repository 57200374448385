import { Typography } from "@material-tailwind/react";
import React from "react";
import contactImg from "../assets/img/troy_tech_web_bg.jpg";
import webprocess from "../assets/img/troy-tech_infographic-web-design-process.png";
import webmain from "../assets/img/troy-tech-webs-scaled.jpeg";
import domain from "../assets/img/troy-tech-Domain.png";
import { webbtools } from "../data/webtools";
import MyMarquee from "../components/MyMarquee";
import { motion } from "framer-motion";

const Website = () => {
  return (
    <div>
      <div
        className="w-full h-[250px] relative before:content-[''] before:w-full before:bg-[#0b1643] before:opacity-65 before:absolute before:top-0 before:left-0 before:h-full before:z-0 "
        style={{ backgroundImage: `url(${contactImg})`, backgroundPosition: "center", backgroundSize: "cover" }}>
        <div className="w-full h-full flex flex-col items-center justify-center relative">
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.2 }}
            className="xl:w-[70%] flex justify-center items-center">
            <Typography variant="h2" className="capitalize text-3xl md:text-4xl">
              website development
            </Typography>
          </motion.div>
          <div className="max-w-[650px] text-center p-4">
            <motion.p
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ type: "spring", stiffness: 100, damping: 10, delay: 1 }}
              className="text-sm md:line-clamp-none line-clamp-3">
              TROY TECHNOLOGY is an experienced and well established website development company specializing in web design, web development and maintenance.
            </motion.p>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-center justify-center">
        <div className="xl:w-[70%] flex flex-wrap gap-8 py-4 md:py-16">
          <div className="flex-grow w-96">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.2 }}
              className="w-full flex justify-center items-center">
              <img src={webprocess} alt="what we do" />
            </motion.div>
          </div>
          <div className="flex-grow w-96 flex items-center px-4">
            <motion.div initial={{ opacity: 0, y: 100 }} animate={{ opacity: 1, y: 0 }} transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.2 }} className="">
              <Typography variant="h3" className="mb-8 font-semibold">
                Website Construction
              </Typography>
              <Typography variant="paragraph" className="leading-7 mb-4">
                Owing to technological advancement and the sky rocketing advantages of the internet, Troy technology is providing small and large scale companies with intuitive and
                top notch websites for a cool price, giving your company the needed flexibility to disseminate information across the lengths and breaths of the globe.
              </Typography>
              <Typography color="green">Contact us now to help put your product or business on the global platform today.</Typography>
            </motion.div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-center justify-center mt-8">
        <div className="xl:w-[70%] flex flex-wrap gap-8 py-4 md:py-16">
          <div className="flex-grow w-96 px-4 order-last md:order-first flex items-center">
            <motion.div initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1, y: 0 }} transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.2 }} className="">
              <Typography variant="h3" className="mb-8 font-semibold">
                Website Maintenance
              </Typography>
              <Typography variant="paragraph" className="leading-7 mb-4">
                Do you have the need for regular website maintenance but no time or desire to do it yourself, or improper management which is closing your face to the world? Troy
                Web Services offers website maintenance on a pay-as-you-go basis. The Website maintenance can include adding, removing, or editing text and graphics, modifying
                products in your online shopping cart, updating news events, uploading documents, and often emergency fixes. If you’re not sure about the issue or can’t explain it,
                let us take a look and we can often diagnose it within minutes. The ultimate is hosting your site yourself or having it hosted by TROY TECHNOLOGY.
              </Typography>
            </motion.div>
          </div>
          <div className="flex-grow w-96">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.2 }}
              className="w-full flex justify-center items-center">
              <img src={webmain} alt="what we do" />
            </motion.div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-center justify-center">
        <div className="xl:w-[70%] flex flex-wrap gap-8 py-4 md:py-16">
          <div className="flex-grow w-96">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.2 }}
              className="w-full flex justify-center items-center">
              <img src={domain} alt="what we do" />
            </motion.div>
          </div>
          <div className="flex-grow w-96 px-4">
            <motion.div initial={{ opacity: 0, y: 100 }} animate={{ opacity: 1, y: 0 }} transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.2 }} className="py-20">
              <Typography variant="h3" className="mb-8 font-semibold">
                Domain registration & Hosting
              </Typography>
              <Typography variant="paragraph" className="leading-7 mb-4">
                Domain names are used to establish a unique identity. Organizations can choose a domain name that corresponds to their name, helping Internet users to reach them
                easily.
              </Typography>
              <Typography variant="paragraph" className="leading-7 mb-4">
                A generic domain is a name that defines a general category, rather than a specific or personal instance, for example, the name of an industry, rather than a company
                name. Some examples of generic names are <span className="font-bold italic">books.com, music.com</span> and <span className="font-bold italic">travel.info.</span>
                Companies have created brands based on generic names, and such generic domain names may be valuable.
              </Typography>
              <Typography variant="paragraph" className="leading-7">
                Own a domain for your business or company today to make your clients or customers to reach you easily.
              </Typography>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Our Client */}
      <MyMarquee data={webbtools} caption="our web technologies" />
    </div>
  );
};

export default Website;
