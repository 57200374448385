import React from "react";
import * as Bi from "react-icons/bi";
import footerBg from "../assets/img/bg3.jpg";
import { Link, NavLink } from "react-router-dom";
import { Typography } from "@material-tailwind/react";
import logo from "../assets/img/troy-tech-logo.png";
import { motion } from "framer-motion";

const Footer = () => {
  return (
    <div
      className="w-full  relative before:content-[''] before:w-full before:bg-[#0b1643] before:opacity-65 before:absolute before:top-0 before:left-0 before:h-full before:z-0 "
      style={{ backgroundImage: `url(${footerBg})`, backgroundPosition: "bottom", backgroundSize: "cover" }}>
      {/* top footer */}
      <div className="relative w-full flex justify-center py-4">
        <div className="xl:w-[70%] flex justify-between items-center gap-4 flex-wrap">
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.2 }}
            className="flex items-center flex-grow w-80 ">
            <div className="w-[200px] md:w-[300px]">
              <Link to="/" className="flex justify-center items-center cursor-pointer">
                <img src={logo} alt="troyTech" className="w-20" />
                <Typography variant="h3" className="capitalize text-xl">
                  troy technoloy ghana limited
                </Typography>
              </Link>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.2 }}
            className="bg-slate-600 flex-grow px-8 w-64 ">
            <Typography variant="h3" className="capitalize text-xl mb-4 text-blue-500">
              quick links
            </Typography>
            <div className="flex flex-col gap-2">
              <NavLink to="/website" className="font-extralight">
                Website
              </NavLink>
              <NavLink to="/networking" className="font-extralight">
                Networking
              </NavLink>
              <NavLink to="/software" className="font-extralight">
                Software
              </NavLink>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.2 }}
            className="bg-slate-800 flex-grow px-8 w-96">
            <Typography variant="h3" className="capitalize text-xl mb-4 text-blue-500">
              contact
            </Typography>
            <div className="flex flex-col gap-4">
              <div className="flex">
                <Bi.BiMap className="mr-4 text-2xl" />
                <Typography className="w-80">Troy Technology Ghana Limited, 10Th Barbara Street Community 17, Lashibi Old Standard, Tema, Greater Accra - Ghana</Typography>
              </div>
              <div className="flex">
                <Bi.BiPhone className="mr-4 text-2xl" />
                <Typography>(233) 209-358-561 / (233) 20-335-3010</Typography>
              </div>
              <div className="flex">
                <Bi.BiEnvelope className="mr-4 text-2xl" />
                <Typography>info@troytechgh.com</Typography>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* copyright */}
      <div className=" bg-blue-800 w-full flex justify-center items-center z-10" style={{ background: "rgba(30, 64, 175, 0.4)", backdropFilter: "blur(3px)" }}>
        <div className="xl:w-[70%] py-4 flex justify-between items-center px-4">
          <div className="">
            <Typography variant="paragraph" className="capitalize font-light">
              copyright &copy; {new Date().getFullYear()} - troy technology ghana limited
            </Typography>
          </div>
          <div className="flex gap-3">
            <Link to="https://web.facebook.com/troytechgh" className="hover:text-blue-700">
              <Bi.BiLogoFacebook className="text-2xl" />
            </Link>
            <Link to="https://www.instagram.com/troytechghana/" className="hover:text-pink-700">
              <Bi.BiLogoInstagram className="text-2xl" />
            </Link>
            <Link to="https://wa.me/233209358561" className="hover:text-green-700">
              <Bi.BiLogoWhatsapp className="text-2xl" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
